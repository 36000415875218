.MuiSelect-popper {
    background: $bg;
    border-radius: 10px;
    box-shadow: 0px 0px 9px 0px #00000080;
    overflow: auto;
}

.MuiSelect-listbox {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
}

.select {
    position: relative;
    width: 100%;
    background: $bg-second;
    border: 0;
    min-height: 35px;
    border-radius: 10px;
    color: $color;
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;

    &__option {
        cursor: pointer;
        user-select: none;
        padding: 10px 5px;
        width: 100%;
        color: $color;
        box-sizing: border-box;
        background: $bg-second;
        border-radius: 5px;

        @include hover {
            background: $tr-grey;
        }

        &.Mui-selected {
            background: $bg;
        }
    }

    &__values {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    &__value {
        padding: 5px;
        background: $bg;
        flex: 1;
        border-radius: 5px;
    }
}

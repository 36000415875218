.colors {
    &-picker {
        display: flex;
        flex-wrap: wrap;
    }
}
.color {
    &-picker {
        width: 40px;
        height: 40px;
        border: 1px solid grey;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 5px;

        &_active {
            border: 1px solid $color;
        }
    }
}

.file {
    border: 1px solid transparent;
    box-sizing: border-box;
    &_active {
        border: 1px solid rgba(185, 87, 87, 0.479);
    }
    &__inner {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
    }
    &__preview {
        width: 100px;
        height: 100px;
        object-fit: cover;
        font-size: 100px;
        text-align: center;
        border-radius: 5px;
    }
    &__body {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
    }
    &__str {
        display: flex;
        &-red {
            color: $red;
        }
        &-underline {
            text-decoration: underline;
        }
    }
    input {
        display: none;
    }
}

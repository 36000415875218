.form {
    display: flex;
    flex-direction: column;
    gap: $gap2;

    &__element {
        display: flex;
        gap: $gap2;
    }

    &__captcha {
        display: flex;
        justify-content: center;
    }

    &__label {
        font-size: 14px;
        line-height: 20px;

        span {
            color: grey;
        }
    }
    &__btns {
        display: flex;
        flex-wrap: wrap;
        gap: $gap2;
    }
    &__links {
        display: flex;
        flex-wrap: wrap;
    }
    &__link {
        text-decoration: underline;
        color: $theme-bg;
        padding: 5px;
        box-sizing: border-box;
        font-size: 15px;
        cursor: pointer;
        flex: 1;
        text-align: center;
    }
    &__half {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $gap2;
    }
    &__doc {
        font-size: 10px;
        text-align: center;

        a {
            text-decoration: underline;
        }
    }
}

@import '../../assets/scss/vars';

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    transition: 0.3s;

    &.open {
        visibility: visible;
    }
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000005e;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

    &.open {
        opacity: 1;
        visibility: visible;
    }
}

.card {
    background: $bg-second;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;

    opacity: 0;
    transform: translateY(-100px);
    visibility: hidden;

    transition: 0.3s;

    &.open {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
}

.title {
    font-size: 35px;
    color: $color;
    width: 100%;
    text-align: center;
}

.actions {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.content {
    text-align: center;
    color: $theme-color;
}

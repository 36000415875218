.popup {
    $media: 750px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &_show {
        visibility: visible;
        opacity: 1;
    }

    &__bg {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.452);
        visibility: hidden;
        opacity: 0;
        transition: 0.2s;

        &-show {
            visibility: visible;
            opacity: 1;
        }
    }

    &__inner {
        max-width: 600px;
        width: 100%;
        background: $bg;
        color: $color;
        transition: 0.2s;
        visibility: hidden;
        opacity: 0;
        transform: translate3d(0, 30px, 0);
        border-radius: 5px;
        padding: $gap;
        display: flex;
        flex-direction: column;
        gap: $gap;
        box-sizing: border-box;
        height: fit-content;
        max-height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &-show {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        @media (max-width: $media) {
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 100%;
            border-radius: 0;
            margin: 0;
        }
    }
    &__title {
        display: flex;
        font-weight: bold;
    }
}

.container {
    width: 100%;
    max-width: $mainWidth;
    margin: 0 auto;
    padding-left: $sidebarWidth + 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: $gap;
    padding-bottom: 80px;
    padding-top: $gap;

    @media (max-width: $mobileMedia) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 150px;
    }
}

@import 'vars';
@import 'reset';
@import 'mixins';
@import '~croppie/croppie.css';
@import './components/main.scss';
@import './components/navbar.scss';
@import './components/block.scss';
@import './components/popup.scss';
@import './UI/checkbox.scss';
@import './UI/form.scss';
@import './components/anime.scss';
@import './UI/menu.scss';
@import './components/profile.scss';
@import './components/notification.scss';
@import './UI/select.scss';
@import './UI/counter.scss';
@import './UI/loader.scss';
@import './UI/text.scss';
@import './UI/file.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import './UI/colors.scss';
@import './UI/card.scss';
@import './UI/sidebar.scss';

body {
    @include theme(#4d65ac);

    --bg: #e4e7ed;
    --bg-second: #{darken(#e4e7ed, 5)};
    --color: #2e2e2e;

    --theme-color: white;

    background: $bg-second;
    min-height: 100vh;

    font-family: 'Open Sans', sans-serif;

    &.dark {
        --bg: #282828;
        --bg-second: #{lighten(#282828, 5)};
        --color: white;
    }

    &.green {
        @include theme(#58ac4d);
    }

    &.red {
        @include theme(#ac4d4d);
    }
    &.orange {
        @include theme(#ac7c4d);
    }
    &.dark-theme {
        @include theme(#282828);
    }
    &.light-theme {
        @include theme(#e4e7ed);
        --theme-color: rgb(53, 53, 53);
    }
    &.purple {
        @include theme(#8a5b8a);
    }

    &.no-transitions {
        *,
        *:after,
        *:before {
            transition: 0s !important;
        }
    }

    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: $theme-bg;
}

* {
    outline: none;
}

#fixed {
    position: fixed;
    bottom: 15px;
    right: 15px;

    @media (max-width: $mobileMedia) {
        bottom: 85px;
    }
}

.hidden {
    overflow: hidden;
}

img.blur {
    filter: blur(10px);
}

.notifications {
    display: flex;
    flex-direction: column;
}
.notification {
    display: flex;
    align-items: center;
    background: $bg-second;
    margin-bottom: 10px;
    border-radius: 5px;

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    &__text {
        flex: 1;
        font-size: 15px;
        line-height: 20px;
        padding: 10px;

        a {
            text-decoration: underline;
        }
    }
}

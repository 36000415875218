.text {
    &-tab {
        a {
            text-decoration: underline;
        }
        margin-top: 10px;
        &:first-child {
            margin-top: 0;
        }
        &__title {
            padding: 5px;
            cursor: pointer;
            user-select: none;
            border-left: 3px solid $bg-second;
        }
        &__body {
            display: none;
            &-active {
                display: block;
            }
            padding: 5px 10px 15px 10px;
            font-size: 15px;
            line-height: 20px;

            ol,
            ul {
                padding-left: 20px;
                list-style: decimal;
                li {
                    margin-top: 10px;
                }
            }
        }
    }
}

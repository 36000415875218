$blue: blue;
$red: #b46969;
$green: #b4b469;
$purple: #9c579c;

$margin: 20px;
$sidebarWidth: 70px;
$mobileMedia: 800px;
$mainWidth: 1280px;

$gap: 20px;
$gap2: 10px;

$grey: rgb(131, 131, 131);
$light-grey: rgb(187, 187, 187);
$tr-grey: rgba(201, 201, 201, 0.1);

$color: var(--color);
$bg: var(--bg);
$bg-second: var(--bg-second);
$shadow: 0 0 5px -3px black;

$theme-bg: var(--theme-bg);
$theme-bg-hover: var(--theme-bg-hover);
$theme-bg-active: var(--theme-bg-active);
$theme-color: var(--theme-color);

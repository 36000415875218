@import '../../../assets/scss/mixins';
@import '../../../assets/scss/vars';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.msg {
    color: #c9c975;
    font-size: 11px;
    padding-left: 10px;

    &.error {
        color: #c97575;
    }
}

.field {
    margin: 0;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    padding: 10px 20px;
    line-height: 20px;
    background: $bg-second;
    color: $color;

    &:disabled {
        color: grey;
    }
}

.textarea {
    resize: vertical;
    min-height: 100px;
}

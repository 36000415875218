@mixin hover($args...) {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

@mixin theme($c) {
    --theme-bg: #{$c};
    --theme-bg-hover: #{darken($c, 10)};
    --theme-bg-active: #{darken($c, 20)};
}

.block {
    $radius: 5px;
    box-shadow: $shadow;

    &__body {
        background: $bg;
        color: $color;
        box-sizing: border-box;
        border-radius: 0 0 $radius $radius;
        font-size: 15px;
        line-height: 20px;

        span {
            color: grey;
        }

        padding: $gap2;
        width: 100%;
        box-sizing: border-box;
        position: relative;
    }

    &__title {
        width: 100%;
        background: $theme-bg;
        color: $theme-color;
        padding: 15px 10px;
        text-align: center;
        box-sizing: border-box;
        border-radius: $radius $radius 0 0;
    }
    &__links {
        display: flex;
        flex-wrap: wrap;

        a {
            margin-right: 10px;
            text-decoration: underline;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__info {
        text-align: center;
        color: $color;
    }

    &__loader {
        padding: $gap;
        background: $bg;
        color: $color;
        border-radius: $radius;
    }
}

@import '../../../assets/scss/mixins';
@import '../../../assets/scss/vars';

.button {
    border: 0;
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 20px;
    position: relative;
    align-items: center;

    &.default {
        &:not(:disabled) {
            background: rgba(201, 201, 201, 0.1);
            color: $color;

            @include hover {
                background: rgba(0, 0, 0, 0.1);
            }

            &.active {
                background: rgba(0, 0, 0, 0.1);
            }

            &:active {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &.theme {
        &:not(:disabled) {
            background: $theme-bg;
            color: $theme-color;

            a {
                color: $theme-color;
            }

            @include hover {
                background: $theme-bg-hover;
            }

            &.active {
                background: $theme-bg-hover;
            }

            &:active {
                background: $theme-bg-active;
            }
        }
    }

    &.transparent {
        &:not(:disabled) {
            color: $color;
            background-color: transparent;

            @include hover {
                background: rgba(0, 0, 0, 0.1);
            }

            &.active {
                background: rgba(0, 0, 0, 0.1);
            }

            &:active {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &:disabled,
    &.disabled {
        background: #3e3e3e !important;
        color: white !important;
        cursor: initial !important;
    }
}

.content {
    flex: 1;
    text-align: center;
    &.hidden {
        visibility: hidden;
    }
}

.alert {
    position: absolute;
    right: 0;
    top: 0;
    background: #bf3f3f;
    min-width: 20px;
    box-sizing: border-box;
    padding: 0 5px;
    line-height: 20px;
    text-align: center;
    font-size: 10px;
    border-radius: 20px;
    color: white;
    // transform: translate(50%, -50%);
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
}

.navbar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    padding: 10px;
    background: $theme-bg;
    color: $theme-color;
    box-shadow: $shadow;

    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    &__item {
        $size: 50px;
        position: relative;
        display: flex;

        @include hover {
            &:after {
                transform: translate3d(0, -50%, 0);
                visibility: visible;
                opacity: 1;
            }
        }

        &:after {
            transition: 0.2s;
            content: attr(data-title);
            position: absolute;
            top: 50%;
            left: calc(100% + 20px);
            visibility: hidden;
            opacity: 0;
            transform: translate3d(-20px, -50%, 0);
            padding: 5px 10px;
            border-radius: 5px;
            pointer-events: none;

            background: $bg;
            color: $color;
            box-shadow: $shadow;
        }
    }

    @media (max-width: $mobileMedia) {
        bottom: 0;
        width: 100%;
        height: fit-content;
        top: initial;
        flex-direction: row;

        &__item {
            flex: 1;
            &:after {
                content: initial;
            }
        }

        &__button {
            flex: 1;
        }
    }
}

.button {
    border-radius: 5px;

    &.normal {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        img {
            width: 30px;
            height: 30px;
        }
    }

    img {
        border-radius: 5px;
        background: white;
    }
}

.container {
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
}

.button {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
}

.animes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $gap;
}

.anime {
    $width: 250px;
    $radius: 10px;
    flex: 1;
    min-width: $width;
    max-width: 400px;
    background: $bg;
    color: $color;
    border-radius: $radius;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include hover {
        box-shadow: $shadow;
    }

    &__rating {
        position: absolute;
        top: 50px;
        left: 0;
        padding: 10px;
        border-radius: 0 $radius $radius 0;
        color: white;
        background: $green;
    }

    &__type {
        position: absolute;
        top: 100px;
        left: 0;
        padding: 10px;
        border-radius: 0 $radius $radius 0;
        color: white;
        background: $red;
        font-size: 15px;
    }

    &__img {
        width: 100%;
        object-fit: cover;
        border-radius: $radius;
        aspect-ratio: 1/1.2;
        height: auto;
    }
    &__title {
        text-align: center;
        margin: 15px 0;
        padding: 0 5px;
    }
    &__year {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        border-radius: $radius 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
    }
    &__status {
        position: absolute;
        top: 150px;
        left: 0;
        padding: 10px;
        border-radius: 0 $radius $radius 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;

        &-ongoing {
            background-color: $purple;
        }
        &-released {
            background-color: $green;
        }
        &-anons {
            background-color: $red;
        }
    }
    &__userstatus {
        position: absolute;
        right: 0;
        top: 50px;
        background: $theme-bg;
        color: $theme-color;
        padding: 5px 10px;
        border-radius: 5px 0 0 5px;
    }

    &-detail {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__body {
            display: flex;
            gap: $gap2;

            @media (max-width: $mobileMedia) {
                flex-direction: column-reverse;
            }
        }
        &__buttons {
            display: flex;
            flex-wrap: wrap;
            gap: $gap2;
        }
        &__img {
            img {
                cursor: zoom-in;
                width: 300px;

                @media (max-width: $mobileMedia) {
                    width: 100%;
                }
            }
        }
        &__link {
            display: block;
            color: $color;
            text-decoration: underline;
        }
        &__full {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            z-index: 100;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s;
            cursor: zoom-out;
            user-select: none;

            &-active {
                visibility: visible;
                opacity: 1;
                background: rgba(0, 0, 0, 0.541);
            }
        }
        &__item {
            padding: 5px;
            background: $tr-grey;
            color: $grey;

            span {
                color: $color;
            }
        }
        &__infos {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: $gap2;
        }
        &__description {
            border-left: 5px solid $theme-bg;
            padding: 10px;
        }
        &__info {
            border-radius: 5px;
            font-size: 15px;
            line-height: 20px;
        }
        &__player {
            width: 100%;
            height: 600px;
        }
        &__genre {
            margin-left: 5px;
            text-decoration: underline;
        }
        &__genres {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__delete {
        position: absolute;
        top: 0;
        right: 0;
        background: $red;
        color: white;
        padding: 10px;
        border-radius: 0 $radius;
        cursor: pointer;
    }

    &-mini {
        $size: 50px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        // overflow: hidden;
        position: relative;
        background: $bg-second;

        &__container {
            display: flex;
            flex-direction: column;
            gap: $gap2;
        }

        &__dot {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            user-select: none;
            opacity: 0.5;

            &-ongoing {
                background: $purple;
            }
            &-released {
                background: $green;
            }
            &-anons {
                background: $red;
            }
        }
        &__content {
            flex: 1;
            overflow: hidden;
        }
        &__link {
            align-items: center;
            display: flex;
            flex: 1;
            overflow: hidden;
            padding: 5px;
            padding-right: 10px;
            gap: 5px;
        }
        &__img {
            display: flex;
            align-items: center;

            img {
                width: $size - 10px;
                height: $size - 10px;
                border-radius: $size;
                box-sizing: border-box;
                object-fit: cover;

                &.bordered {
                    border: 3px solid #bf3f3f;
                }
            }
        }
        &__title {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
        }
    }
}

.profile {
    display: flex;
    $media_1: 1030px;
    $media_2: $mobileMedia;
    gap: $gap;

    @media (max-width: $media_2) {
        flex-direction: column;
    }

    &__main {
        display: flex;
        flex: 1;
        overflow: hidden;
        gap: $gap;

        @media (max-width: $media_1) {
            flex-direction: column-reverse;
        }
    }

    &__side {
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: $gap;

        @media (max-width: $media_2) {
            width: 100%;
        }
    }

    &__info {
        font-size: 15px;
        line-height: 20px;
    }

    &__description {
        padding: 5px;
        border-left: 3px solid $theme-bg;
        box-sizing: border-box;
    }

    &__avatar {
        img {
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    &__block {
        flex: 1;
    }

    &__infoblock {
        display: flex;
        flex-direction: column;
        gap: $gap2;
    }

    &__menu {
        @media (max-width: $media_1) {
            width: 100%;
        }
    }

    &__content {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: $gap;

        @media (max-width: $media_2) {
            flex-direction: column-reverse;
        }
    }
    &__chart {
        background: $bg;
        height: 300px;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
    }
}

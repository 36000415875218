.counter {
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;
    background: $bg-second;
    border-radius: 5px;

    &__field {
        flex: 1;
        text-align: center;
    }
}

.sidebar {
    display: flex;

    &__body {
        flex: 1;
        padding-right: 10px;
    }

    &__side {
        padding-left: 10px;
        width: 300px;
    }
}

.checkbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__title {
        margin: 5px 10px;
        font-size: 15px;
    }
    &__body {
        width: 40px;
        height: 20px;
        background: #000;
        display: flex;
        align-items: center;
        border-radius: 100px;
        cursor: pointer;
        user-select: none;
    }
    &__point {
        height: 22px;
        width: 22px;
        border-radius: 100px;
        background: white;
        transform: translate3d(-1px, 0, 0);
        background: $red;
        transition: 0.2s;

        &-checked {
            transform: translate3d(20px, 0, 0);
            background: $green;
        }
    }
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    $width: 250px;
    $radius: 10px;
    flex: 1;
    min-width: $width;
    margin: 10px;
    background: $bg;
    color: $color;
    border-radius: $radius;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;

    @include hover {
        box-shadow: $shadow;
    }

    &__rating {
        position: absolute;
        top: 50px;
        left: 0;
        padding: 10px;
        border-radius: 0 $radius $radius 0;
        color: white;
        background: #b4b469;
    }

    &__type {
        position: absolute;
        top: 100px;
        left: 0;
        padding: 10px;
        border-radius: 0 $radius $radius 0;
        color: white;
        background: #b46969;
        font-size: 15px;
    }

    &__img {
        width: 100%;
        height: $width * 1.3;
        object-fit: cover;
        border-radius: $radius;
    }

    &__title {
        text-align: center;
        margin: 15px 0;
        padding: 0 5px;
    }

    &__year {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        border-radius: $radius 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
    }

    &__status {
        position: absolute;
        right: 0;
        top: 50px;
        background: $theme-bg;
        color: $theme-color;
        padding: 5px 10px;
        border-radius: 5px 0 0 5px;
    }

    &__delete {
        position: absolute;
        top: 0;
        right: 0;
        background: $red;
        color: white;
        padding: 10px;
        border-radius: 0 $radius;
        cursor: pointer;
    }
}
